import React, {Component} from 'react';
import ClassNames from 'classnames';
import { Link } from 'react-scroll';

import './MenuList.less';

class MenuList extends Component {
    handleSetActive = (item) => {
        // console.log('handleSetActive', item);
    }

    handleSetInactive = (item) => {
        // console.log('handleSetInactive', item);
    }

    render() {
        // const {isMobile, className} = this.props;
        const {className} = this.props;

        const classes = ClassNames('MenuList', className);

        return (
            <ul className={classes}>
                <li>
                    <Link
                        isDynamic
                        activeClass="active"
                        className="MenuList__btn"
                        to="start"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onSetActive={this.handleSetActive}
                        onSetInactive={this.handleSetInactive}
                    >
                        Start
                    </Link>
                </li>
                <li>
                    <Link
                        isDynamic
                        activeClass="active"
                        className="MenuList__btn"
                        to="resume"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onSetActive={this.handleSetActive}
                        onSetInactive={this.handleSetInactive}
                    >
                        Resume
                    </Link>
                </li>
                <li>
                    <Link
                        isDynamic
                        activeClass="active"
                        className="MenuList__btn"
                        to="portfolio"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onSetActive={this.handleSetActive}
                        onSetInactive={this.handleSetInactive}
                    >
                        Portfolio
                    </Link>
                </li>
                <li>
                    <Link
                        isDynamic
                        activeClass="active"
                        className="MenuList__btn"
                        to="blog"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onSetActive={this.handleSetActive}
                        onSetInactive={this.handleSetInactive}
                    >
                        Blog Posts
                    </Link>
                </li>
                <li>
                    <Link
                        isDynamic
                        activeClass="active"
                        className="MenuList__btn"
                        to="contact"
                        spy={true}
                        smooth={true}
                        duration={500}
                        onSetActive={this.handleSetActive}
                        onSetInactive={this.handleSetInactive}
                    >
                        Contact
                    </Link>
                </li>
            </ul>
        )
    }
}

export default MenuList;
