import React, {Component, lazy, Suspense} from 'react';
import Hero from './components/Hero';
import Header from './components/Header/Header';

// todo: this needs to be commented for the critical
import './critical/critical.less';

const Resume = lazy(() => import('./components/Resume/Resume'));
const Portfolio = lazy(() => import('./components/Portfolio/Portfolio'));
const Contact = lazy(() => import('./components/Contact/Contact'));
const Blog = lazy(() => import('./components/Blog/Blog'));

class MySite extends Component {
    render() {
        return (
            <React.Fragment>
                <Header/>
                <Hero/>
                <Suspense fallback={<div/>}>
                    <Resume/>
                </Suspense>
                <Suspense fallback={<div/>}>
                    <Portfolio/>
                </Suspense>
                <Suspense fallback={<div/>}>
                    <Blog/>
                </Suspense>
                <Suspense fallback={<div/>}>
                    <Contact/>
                </Suspense>
            </React.Fragment>
        );
    }
}

export default MySite;
