import React, { Component } from 'react';
import Responsive from 'react-responsive';
import MobileNav from './MobileNav';
import Menu from './Menu';

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Device = props => <Responsive {...props}  maxWidth={991} />;

class Header extends Component {
    render() {
        return (
            <React.Fragment>
                <Desktop>
                    <Menu/>
                </Desktop>
                <Device>
                    <MobileNav/>
                </Device>
            </React.Fragment>
        );
    }
}

export default Header;
