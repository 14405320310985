import React, { Component } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button/Button';
import ImageCircle from './ImageCircle';
import MenuList from './MenuList';
import MenuSocial from './MenuSocial';

import './MobileNav.less'

class MobileNav extends Component {
    state = {
        isOpen: false
    }

    toogleMobileNav = () => {
        this.setState((state) => ({
            isOpen: !state.isOpen
        }));
    }

    render() {
        const {isOpen} = this.state;
        const classes = isOpen ? classnames('MobileNav', 'MobileNav-open') : classnames('MobileNav')

        return (
            <nav className={classes}>
                <Button className="MobileNav__btn" isCircle type="primary"
                    ariaLabel="Mobile nav button" 
                    noTransition onClick={this.toogleMobileNav}>
                    <FontAwesomeIcon icon={faBars}/>
                </Button>
                {(
                     <div className="MobileNav__Panel">
                        <div className="MobileNav__wrapper">
                            <ImageCircle imageSrc="images/me.webp" altText="my photo"/>
                            <MenuList {...{isOpen}} isMobile/>
                            <MenuSocial/>
                        </div>
                        <Button className="MobileNav__Panel__btn" isCircle 
                            circleSize="sm" type="primary"
                            ariaLabel="Mobile panel button" 
                            noTransition onClick={this.toogleMobileNav}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </div>
                )}
            </nav>
        );
    }
}

export default MobileNav;
