import React, {Component} from 'react';
import Button from './Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faArrowDown, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';
import { Element } from 'react-scroll';
import {images} from '../config';

import './Hero.less';

class Hero extends Component {
    render() {
        let imgSrc = null;
        const sources = images.resolutions.map(({key, media}) => {
            const srcSet = images.imageUrl;
            imgSrc = imgSrc || srcSet;
            return <source {...{key, media, srcSet}}/>;
        });

        sources.push(<img key="img" loading="lazy" 
            className="animated infinite zooming visible" 
            src={imgSrc} alt={'hugofqueiros'} />
        );

        return (
            <Element name="start">
                <section className="Hero section padding-v-60">
                    <picture className="Hero__image">{sources}</picture>
                    <div className="Hero__container container-padded">
                        <div className="Hero__inittext">
                            <FontAwesomeIcon icon={faComments}/>
                            {'Do you have any questions? Email me @ '}
                            <a href="mailto:hugofqueiros@gmail.com" className="Hero__email">hugofqueiros@gmail.com</a>
                        </div>
                        <div className="Hero__container-bottom">
                            <h1>Hi! I'm <strong>Hugo Queirós</strong></h1>
                            <h2>Front-end web developer / IT Engineer / Robotics Engineer</h2>
                            <div className="Hero__buttons">
                                <Link
                                    isDynamic
                                    to="resume"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                >
                                    <Button className={'Hero__button'} type="primary" size="lg" label={'Go to my resume!'} isAnimated>
                                        <FontAwesomeIcon icon={faArrowDown}/>
                                    </Button>
                                </Link>
                                <Button href="docs/resume-hugoqueiros.pdf" download="resume-hugoqueiros.pdf" className={'Hero__button'} size="lg" label={'Download my Resume'} isAnimated>
                                    <FontAwesomeIcon icon={faFilePdf}/>
                                </Button>
                                <Button href="docs/Hugo-Queiros-CV.pdf" download="resume-hugoqueiros.pdf" className={'Hero__button'} size="lg" label={'Download my CV'} isAnimated>
                                    <FontAwesomeIcon icon={faFilePdf}/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            </Element>
        )
    }
};

export default Hero;
