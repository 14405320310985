module.exports = {
    images: {
        imageUrl: 'images/code.jpg',
        resolutions: [
            {key: 'hw1', media: '(min-width: 769px)'},
            {key: 'hw2', media: '(min-width: 376px) and (max-width: 768px)'},
            {key: 'hw6', media: '(max-width: 375px)'}
        ]
    }
}
