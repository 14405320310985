import React from 'react';

import './ImageCircle.less';

function ImageCircle({imageSrc, altText}) {
    return (
        <img className="ImageCircle" loading="lazy" src={imageSrc} alt={altText}/>
    );
};

export default ImageCircle;
