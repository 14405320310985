import React, { Component } from 'react';
import MenuList from './MenuList';
import MenuSocial from './MenuSocial';

import './Menu.less';

class Menu extends Component {
    render() {
        return (
            <header className="Menu">
                <div className="Menu__logo">
                    <h1>Hugo <strong>Queirós</strong></h1>
                    <h2>Front-end web developer / IT Engineer / Robotics Engineer</h2>
                </div>
                <MenuList className="Menu__list"/>
                <MenuSocial/>
            </header>
        );
    }
}

export default Menu;
