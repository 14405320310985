import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './styles.less';

function Button(props) {
    const {className, label, labelClass, children, href, size, isAnimated, 
        isCircle, noTransition, type, circleSize, ariaLabel='', ...rest} = props;

    const btnClasses = ClassNames('btn', className, {
        [`btn-${type}`]: type,
        [`btn-${size}`]: size,
        'btn-no-transition': noTransition,
        'btn-circle': isCircle,
        [`btn-circle-${circleSize}`]: circleSize,
        'animated': isAnimated,
        'bounceIn': isAnimated,
        'visible': isAnimated,
    });

    const labelClasses = ClassNames('btn__label', labelClass)

    if (href) {
        return (
            <a className={btnClasses} href={href} aria-label={ariaLabel} {...rest}>
                <span className={labelClasses}>{label}</span>
                {children}
            </a>
        );
    }

    return (
        <button className={btnClasses} aria-label={ariaLabel} {...rest}>
            <span className={labelClasses}>{label}</span>
            {children}
        </button>
    )

};

Button.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    labelClass: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'default']),
    size: PropTypes.oneOf(['xs', 'sm', 'lg']),
    circleSize: PropTypes.oneOf(['xs', 'sm', 'lg']),
    noTransition: PropTypes.bool
};

Button.defaultProps = {
    type: 'default',
    noTransition: false
}

export default Button;

