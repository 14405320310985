import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithub, faMediumM } from '@fortawesome/free-brands-svg-icons';
import Button from '../Button/Button';

import './MenuSocial.less';

function MenuSocial() {
    return (
        <div className="MenuSocial">
            <strong className="text-sm">Check out my social media!</strong>
            <ul className="MenuSocial__social__links">
                <li>
                    <Button href="https://linkedin.com/in/hugoqueiros" target="_blank">
                        <FontAwesomeIcon icon={faLinkedinIn}/>
                    </Button>
                </li>
                <li>
                    <Button href="https://github.com/hugofqueiros" target="_blank">
                        <FontAwesomeIcon icon={faGithub}/>
                    </Button>
                </li>
                <li>
                    <Button href="https://medium.com/@hugofqueiros" target="_blank">
                        <FontAwesomeIcon icon={faMediumM}/>
                    </Button>
                </li>
            </ul>
        </div>
    )

}

export default MenuSocial;
